<script setup>
import { useCssModule } from 'vue';
import { getBadgeProps } from '@@/components/Common/Badges/BadgeProps';

const props = defineProps({
  ...getBadgeProps(['backgroundColor', 'borderColor', 'color', 'shape']),
  paddingX: {
    type: String,
    default: 'tw-px-1.5',
  },
  paddingY: {
    type: String,
    default: 'tw-py-0',
  },
});

const $style = useCssModule();

const allAdditionalClassNames = computed(() => {
  const additionalClassNames = [];

  if (typeof props.additionalClassNames === 'string') {
    additionalClassNames.push(props.additionalClassNames);
  }
  if (Array.isArray(props.additionalClassNames)) {
    additionalClassNames.push(...props.additionalClassNames);
  }

  additionalClassNames.push($style.container);

  return additionalClassNames;
});
</script>

<template>
  <Badge
    :additional-class-names="allAdditionalClassNames"
    border-color="badge-yellow-border-color"
    color="badge-yellow-color"
    :padding-x="paddingX"
    :padding-y="paddingY"
    shape="square"
    :size="size"
  >
    <template #default>
      <slot>Estimated</slot>
    </template>
  </Badge>
</template>

<style module>
.container {
  background-color: white;
}

:global([data-theme="dark"]) .container {
  background-color: black;
}
</style>
